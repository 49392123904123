<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row gutter-b">
      <div class="col-12 col-md-3 pt-0 pb-0">
        <b-form-select
          v-model="selected.year"
          :options="optionYear"
        ></b-form-select>
      </div>
      <div class="col-12 col-md-3 pt-0 pb-0">
        <b-form-select
          v-model="selected.month"
          :options="optionMonth"
        ></b-form-select>
      </div>
      <div
        class="col-12 col-md-3 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_dashboard') ||
          currentUser.role.name == 'Client'
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selectCustomer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div>
      <div
        class="col-12 col-md-3 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_dashboard') ||
          currentUser.role.name == 'Client'
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customerBranch"
            :options="filterCustomerBranch"
          ></b-form-select>
        </div>
      </div>
      <!-- <div class="col-xxl-3">
        <b-form-select v-model="selected" :options="filterCustomer"></b-form-select>
      </div> -->
    </div>
    <div class="row">
      <div class="col-xxl-6 mb-2">
        <b-card>
          <LineChart :chartData="chartDataPresence" />
          <!-- <LineChart /> -->
        </b-card>
      </div>
      <div class="col-xxl-6">
        <b-card>
          <BarChart :chartData="chartDataPatrol" />
        </b-card>
      </div>
      <div class="col-xxl-6">
        <b-card>
          <BarChart :chartData="chartDataDailyReport" />
        </b-card>
      </div>
      <!-- <div class="col-xxl-4">
        <b-card>
          <DoughnutChart />
        </b-card>
      </div> -->
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LineChart from "@/components/lineChart.vue";
import BarChart from "@/components/Bar.vue";
// import DoughnutChart from "@/components/Doughnut.vue";
import { dateToISOString } from "@/utils/helper";
import { debounce } from "debounce";
import { mapGetters } from "vuex";
import mixin from "@/mixins/index";

export default {
  name: "dashboard",
  mixins: [mixin],
  data() {
    let dateNow = new Date();
    return {
      selected: {
        customer: null,
        customerBranch: null,
        month: dateNow.getMonth() + 1,
        year: dateNow.getFullYear(),
      },
      optionYear: [{ value: null, text: "Filter Tahun" }],
      optionMonth: [
        { value: 1, text: "Januari" },
        { value: 2, text: "Februari" },
        { value: 3, text: "Maret" },
        { value: 4, text: "April" },
        { value: 5, text: "Mei" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "Agustus" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Desember" },
      ],
      filterCustomer: [{ value: null, text: "Filter Site Group" }],
      filterCustomerBranch: [{ value: null, text: "Filter Site" }],
      isLoading: false,
      listItem: [],
      // PRECENCE
      chartDataPresence: {},
      dataMustPresent: [],
      dataPresent: [],
      dataAbsent: [],
      dataSick: [],
      dataOtherLeave: [],
      dataLeave: [],

      // PATROL
      chartDataPatrol: {},
      dataTotalReport: [],

      // Daily Report
      chartDataDailyReport: {},
      dataTotalDailyReport: [],

      labelDate: [],
    };
  },
  components: {
    LineChart,
    BarChart,
    // DoughnutChart,
  },
  computed: {
    ...mapGetters(["permission", "currentUser"]),
    getFirstDayIso() {
      const tzOffset = new Date().getTimezoneOffset() * 60000;
      const date = new Date(this.selected.year, this.selected.month - 1);
      const newDate = new Date(date - tzOffset).toISOString().split("T");
      return newDate;
    },
    getMonthIso() {
      const tzOffset = new Date().getTimezoneOffset() * 60000;
      const date = new Date(this.selected.year, this.selected.month, 0);
      const newDate = new Date(date - tzOffset).toISOString().split("T");
      return newDate;
    },
    getLastDayMonth() {
      return new Date(this.selected.year, this.selected.month, 0);
    },
    getDaysInMonth() {
      return new Date(this.selected.year, this.selected.month, 0).getDate();
    },
    selectCustomer: {
      get() {
        return this.selected.customer;
      },
      set(value) {
        this.selected.customer = value;
        if (value === null) {
          this.selected.customerBranch = null;
          this.filterCustomerBranch = [{ value: null, text: "Pilih Site" }];
        } else this.getCustomerBranchList(value);
      },
    },
  },
  methods: {
    dateToISOString,
    defaultData() {
      let date = new Date();
      this.optionYear = [];
      for (
        let limitYear = 2021;
        limitYear <= parseInt(date.getFullYear());
        limitYear++
      ) {
        this.optionYear.push({ value: limitYear, text: limitYear });
      }
    },
    async getData() {
      // customer_branch_id: this.selected.customerBranch,
      const params = {
        customer_id: this.selected.customer,
        start_date: this.dateToISOString(this.getFirstDayIso),
        end_date: this.dateToISOString(this.getMonthIso),
        limit: 50,
      };
      const {
        data: { list: listPresence },
      } = await this.$axios.get(
        `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/graph/${this.selected.customerBranch}`,
        {
          params,
        }
      );
      const {
        data: { list: listPatrol },
      } = await this.$axios.get(
        `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/patrol`,
        {
          customer_branch_id: this.selected.customerBranch,
          params,
        }
      );
      const {
        data: { list: listDailyReport },
      } = await this.$axios.get(
        `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/graph/daily/${this.selected.customerBranch}`,
        {
          params,
        }
      );

      this.labelDate = [];
      this.dataMustPresent = [];
      this.dataPresent = [];
      this.dataAbsent = [];
      this.dataTotalReport = [];
      this.dataTotalDailyReport = [];

      for (let start = 0; start < this.getDaysInMonth + 1; start++) {
        this.dataMustPresent.push(0);
        this.dataPresent.push(0);
        this.dataSick.push(0);
        this.dataLeave.push(0);
        this.dataOtherLeave.push(0);

        this.dataAbsent.push(0);
        this.dataTotalReport.push(0);
        this.dataTotalDailyReport.push(0);
        this.labelDate.push(start);
      }
      for (
        let startPresence = 0;
        startPresence < listPresence.length;
        startPresence++
      ) {
        this.dataMustPresent[
          parseInt(this.sliceFromIsoFormat(listPresence[startPresence].date))
        ] = listPresence[startPresence].must_present;
        this.dataPresent[
          parseInt(this.sliceFromIsoFormat(listPresence[startPresence].date))
        ] = listPresence[startPresence].present;
        this.dataSick[
          parseInt(this.sliceFromIsoFormat(listPresence[startPresence].date))
        ] = listPresence[startPresence].absent;
        this.dataLeave[
          parseInt(this.sliceFromIsoFormat(listPresence[startPresence].date))
        ] = listPresence[startPresence].leave;
        this.dataOtherLeave[
          parseInt(this.sliceFromIsoFormat(listPresence[startPresence].date))
        ] = listPresence[startPresence].other_leave;
      }

      for (
        let startPatrol = 0;
        startPatrol < listPatrol.length;
        startPatrol++
      ) {
        this.dataTotalReport[
          parseInt(this.sliceFromIsoFormat(listPatrol[startPatrol].date))
        ] = listPatrol[startPatrol].total_scan;
      }

      for (
        let startDailyReport = 0;
        startDailyReport < listDailyReport.length;
        startDailyReport++
      ) {
        this.dataTotalDailyReport[
          parseInt(
            this.sliceFromIsoFormat(listDailyReport[startDailyReport].date)
          )
        ] = listDailyReport[startDailyReport].total_report;
      }

      this.chartDataPresence = {
        labels: this.labelDate,
        datasets: [
          {
            label: "Harus Hadir",
            backgroundColor: "#41B883",
            color: "#41B883",
            borderColor: "#41B883",
            data: this.dataMustPresent,
            type: "bar",
          },
          {
            label: "Hadir",
            backgroundColor: "#f87979",
            data: this.dataPresent,
            type: "line",
          },
          {
            label: "Alpa",
            backgroundColor: "#00D8FF",
            data: this.dataAbsent,
            type: "line",
          },
          {
            label: "Sakit",
            backgroundColor: "#9ED763",
            data: this.dataSick,
            type: "line",
          },
          {
            label: "Pergi",
            backgroundColor: "#FFF2B2",
            data: this.dataLeave,
            type: "line",
          },
          {
            label: "Pergi Lainnya",
            backgroundColor: "#0A4650",
            data: this.dataOtherLeave,
            type: "line",
          },
        ],
      };
      this.chartDataPatrol = {
        labels: this.labelDate,
        datasets: [
          {
            label: "Patrol",
            backgroundColor: "#f87979",
            data: this.dataPresent,
          },
        ],
      };
      this.chartDataDailyReport = {
        labels: this.labelDate,
        datasets: [
          {
            label: "Daily Report",
            backgroundColor: "#DD1B16",
            data: this.dataTotalDailyReport,
          },
        ],
      };
      this.isLoading = false;
    },
    async getCustomerBranchList(customer_id) {
      this.isLoading = true;
      if (
        !this.permission.some((item) => item.name === "can_create_customer")
      ) {
        const customerBranch = this.$store.getters.currentUser.customer_branch;
        this.filterCustomerBranch = [{ value: null, text: "Select All" }];
        customerBranch.map((item) => {
          this.filterCustomerBranch.push({
            value: item.id,
            text: item.name,
          });
        });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      } else {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
          )
          .then(({ data }) => {
            this.filterCustomerBranch = [{ value: null, text: "Select All" }];
            data.list.map((item) => {
              this.filterCustomerBranch.push({
                value: item.id,
                text: item.name,
              });
            });
          });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      }
      this.isLoading = false;
    },
    processChange: debounce(function () {
      this.getData();
    }, 500),
    sliceFromIsoFormat(dateISO) {
      return dateISO.slice(8, 10);
    },
  },
  watch: {
    selected: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    this.defaultData();
  },
};
</script>
<style lang="scss"></style>
